<template>
  <div class="page">
    <div class="viewContainer">
      <div class="center" style="min-height: 90vh; max-width: 1200px; margin-top: -50px">
        <img :src="require(`@/assets/imgs/HD_LOGOLedgerBet.png`)" style="width: 50%; margin: 0 auto" @click="goTo('Home')" />
        <p class="title1" style="font-size: clamp(50px, 5vw, 80px); font-family: 'Ethnocentric Rg'; margin: 0 auto; font-weight: 400"><span style="filter: drop-shadow(0px 0px 5px #ffffff)">Coming</span> <span style="color: #32c3f6; filter: drop-shadow(0px 0px 10px #32c3f6)">Soon</span></p>
        <div class="inline" style="">
          <i @click="goToExternal($store.state.social.twitter)" class="fab fa-twitter" style="font-size: 80px"></i>
          <i @click="goToExternal($store.state.social.discord)" class="fab fa-brands fa-discord" style="font-size: 80px"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$store.state.general.privateLeague = false;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.viewContainer {
  height: 100vh;
  width: 100vw;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.fab {
  font-size: 50px;
  color: $white;
  margin: 0px;
  cursor: pointer;
  &:hover {
    color: $active;
    filter: drop-shadow(0px 0px 1px $active);
    //font-weight: 400;
  }
}
</style>

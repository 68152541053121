import Vue from "vue";
import Router from "vue-router";

import ComingSoon from "./views/ComingSoon.vue";
// import Home from "./views/Home.vue";
// import Play from "./views/Play.vue";
// import Register from "./views/Register.vue";
// import LeadBoard from "./views/Leadboard.vue";
// import Create from "./views/Create.vue";
// import MyBet from "./views/MyBet.vue";
// import RegisterLeague from "./views/RegisterLeague.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    //ComingSoon
    // {
    //   path: "/comingsoon",
    //   name: "ComingSoon",
    //   component: ComingSoon,
    // },
    {
      path: "*",
      name: "ComingSoon",
      component: ComingSoon,
    },
    // World league
    // {
    //   path: "*",
    //   name: "Home",
    //   component: Home,
    // },
    // {
    //   path: "/play",
    //   name: "Play",
    //   component: Play,
    // },
    // {
    //   path: "/register",
    //   name: "Register",
    //   component: Register,
    // },
    // {
    //   path: "/leadboard",
    //   name: "LeadBoard",
    //   component: LeadBoard,
    // },
    // {
    //   path: "/mybet",
    //   name: "MyBet",
    //   component: MyBet,
    // },
    // {
    //   path: "/create",
    //   name: "Create",
    //   component: Create,
    // },
    // {
    //   path: "/registerleague",
    //   name: "RegisterLeague",
    //   component: RegisterLeague,
    // },
    /**************************************************************/
    // Private league
    // {
    //   path: "*/:id",
    //   name: "Home",
    //   component: Home,
    // },
    // {
    //   path: "/play/:id",
    //   name: "Play",
    //   component: Play,
    // },
    // {
    //   path: "/register/:id",
    //   name: "Register",
    //   component: Register,
    // },
    // {
    //   path: "/leadboard/:id",
    //   name: "LeadBoard",
    //   component: LeadBoard,
    // },
    // {
    //   path: "/mybet/:id",
    //   name: "MyBet",
    //   component: MyBet,
    // },
  ],
});

<template>
  <div class="footer">
    <div class="col1">
      <div>
        <i @click="goToExternal($store.state.social.twitter)" class="fab fa-twitter" style="width: 100px; height: 10px"></i>
        <i @click="goToExternal($store.state.social.discord)" class="fab fa-brands fa-discord"></i>
      </div>
      <p class="text" style="font-size: 12px">ETHEREUM GAS : 12 gwei</p>
    </div>

    <div class="col2">
      <div class="inline">
        <a @click="goTo('Play')" :class="{ text: true, navLink: true, active: $route.name === 'Play' }">Play</a>
        <a @click="goTo('MyBet')" :class="{ text: true, navLink: true, active: $route.name === 'MyBet' }">My bet</a>
        <a @click="goTo('LeadBoard')" :class="{ text: true, navLink: true, active: $route.name === 'LeadBoard' }">Leaderboard</a>
        <a href="#faq" :class="{ text: true, navLink: true, active: $route.name === 'FAQ' }">FAQ</a>
        <a @click="goTo('FAQ')" :class="{ text: true, navLink: true, active: $route.name === 'FAQ' }">Smart contract</a>
      </div>
    </div>

    <div class="col3">
      <!-- <i class="fab fa-twitch" @click="goToExternal($store.state.social.twitch)"></i> -->
      <i class="message fab fa-solid fa-message"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {},
};
</script>

<style lang="scss" scoped>
.active {
  color: $active;
  filter: drop-shadow(0px 0px 1px $active);
}
.footer {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 100vw;
  text-align: center;
  height: 250px;
  // height: 500px;
  //background: $black;
}
.inline {
  a {
    font-size: clamp(12px, 1vw, 16px);
  }
}
.col1,
.col3 {
  width: 25%;
  height: 200px;
  //border: 1px solid red;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-self: flex-end;
}
.col1 {
  padding-left: 50px;
  text-align: left;
}
.col2 {
  // align-self: flex-start;
  margin-bottom: 500px;
  width: 50%;
  // margin: 0 50px 50px 50px;
}
.col3 {
  padding-right: 50px;
  text-align: right;
}
.fab {
  font-size: 50px;
  color: $white;
  margin: 0px;
  cursor: pointer;
  &:hover {
    color: $active;
    filter: drop-shadow(0px 0px 1px $active);
    //font-weight: 400;
  }
}

.navLink {
  text-transform: uppercase;
  text-decoration: underline;
  font-size: clamp(13px, 1vw, 18px);
  cursor: pointer;
  &:hover {
    color: $active;
    filter: drop-shadow(0px 0px 1px $active);
    //font-weight: 400;
  }
}
.message {
  text-shadow: 0 0 10px $turquoise;
}
@media screen and (max-width: $layout-breakpoint-xlarge) {
  .col1,
  .col3 {
    display: none;
  }
  .col2 {
    width: 100%;
    margin: 50px 0px;
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store/index.js'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    goToExternal(url) {
      window.open(url);
    },
    goTo(name) {
      console.log(name);
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },

    getLeaderboard() {
      this.axios.get(process.env.VUE_APP_API + "/api/participants").then((response) => {
        console.log(response.data);

        let leaderboard = [];
        for (let user of response.data) {
          leaderboard.push({
            rank: 0,
            username: user.username,
            score: user.points,
            payout: user.payout || 0,
          });
        }

        leaderboard.sort((a, b) => {
          return b.score - a.score;
        });
        let rank = 1;
        for (let i = 0; i < leaderboard.length; i++) {
          leaderboard[i].rank = rank;
          if (i < leaderboard.length - 1 && leaderboard[i].score !== leaderboard[i + 1].score) {
            rank++;
          }
        }
        this.$store.state.general.leaderboard = leaderboard;
        this.$forceUpdate();
        //this.$store.state.general.currentUser = leaderboard[0];
      });
    },
    getFakeLeaderboard() {
      // generate fake leaderboard
      let leaderboard = [];
      for (let i = 1; i < 20; i++) {
        let pseudo = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 8; i++) {
          pseudo += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        leaderboard.push({
          rank: i,
          name: pseudo,
          score: Math.round(Math.random() * 100),
          payout: Math.round(Math.random() * 100),
          //transaction: Math.round(Math.random() * 100) - 10,
        });
      }
      this.$store.state.general.leaderboard = leaderboard;
      this.$store.state.general.currentUser = leaderboard[Math.round(Math.random() * 20)];
    },
    getClaimSignature(address) {
      console.log("getClaimSignature");
      if (!this.$store.state.user.address) {
        console.log("No address");
        return;
      }
      // let sign = "0x8d425d6dD23a7d2A0e06B38624EE91159da67D12";
      this.axios.get(process.env.VUE_APP_API + "/participants/claim/" + address).then((response) => {
        console.log(response.data);
      });
    },
    refreshParticipants() {
      console.log("refreshParticipants");
      this.axios.get(process.env.VUE_APP_API + "/participants/refresh").then((response) => {
        console.log(response.data);
      });
    },
    getBestPlayer() {
      console.log("getBestPlayer");
      this.axios.get(process.env.VUE_APP_API + "/participants/bestPlayer").then((response) => {
        console.log(response.data);
      });
    },
    updateParticipant(user) {
      console.log("updateParticipant");
      console.log(user);
      if (!user.address) {
        console.log("No address");
        return;
      }
      this.axios.put(process.env.VUE_APP_API + "/api/participants/" + user.address, user).then((response) => {
        console.log(response.data);
      });
    },

    connectWallet: async function () {
      try {
        const { ethereum } = window;
        if (!ethereum) {
          alert("Get MetaMask!");
          return;
        }
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        this.$store.state.user.address = accounts[0];
        this.walletIsConnected(accounts[0]);
      } catch (error) {
        console.log(error);
      }
    },
    walletIsConnected(act) {
      if (act != "") {
        console.log("Wallet is connected");
        this.axios.get(process.env.VUE_APP_API + "/api/participants/" + act).then((response) => {
          if (response.data.email) {
            console.log("user is register");
            this.$store.state.user.connected = true;
            this.$store.state.user.username = response.data.username;
            this.$store.state.user.points = response.data.points;
            this.$store.state.user.email = response.data.email;
            this.$store.state.user.address = response.data.address;
            this.$store.state.user.bets = response.data.bets || {};
            console.log(this.$store.state.user);
          } else {
            console.log("user is not register");
            this.$store.state.user.connected = false;
            this.goTo("Register");
          }
        });
      } else {
        console.log("Wallet is not connected");
      }
    },

    participate: async function () {
      const contract = await this.getContract();
      await contract.participate({ value: 300 });
    },
  },
})

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount("#app");
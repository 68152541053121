<template>
  <button class="button" v-if="!$store.state.general.privateLeague">
    <img v-if="type == '1'" class="img1" src="@/assets/imgs/FULL.png" />
    <img v-if="type == '1'" class="img2" src="@/assets/imgs/STROKE.png" />
    <img v-if="type == '2'" class="img2" src="@/assets/imgs/type2.png" />
    <img v-if="type == '3'" class="img3" src="@/assets/imgs/type3.png" />
    <img v-if="type == '4'" class="img4" src="@/assets/imgs/type5.png" />
    <img v-if="type == '5'" class="img5" src="@/assets/imgs/FULL.png" />
    <img v-if="type == '5'" class="img6" src="@/assets/imgs/STROKE.png" />
    <img v-if="type == '7'" class="img7" src="@/assets/imgs/FULL.png" />
    <img v-if="type == '7'" class="img7" src="@/assets/imgs/STROKE.png" />
    <div class="content">
      <slot></slot>
    </div>
  </button>

  <button class="button" v-else>
    <img v-if="type == '1'" class="img1" src="@/assets/imgs/FULL_purple.png" />
    <img v-if="type == '1'" class="img2" src="@/assets/imgs/STROKE_purple.png" />
    <img v-if="type == '2'" class="img2" src="@/assets/imgs/type4.png" />
    <img v-if="type == '3'" class="img3" src="@/assets/imgs/type3.png" />
    <img v-if="type == '4'" class="img4" src="@/assets/imgs/type6.png" />
    <img v-if="type == '5'" class="img5" src="@/assets/imgs/FULL_purple.png" />
    <img v-if="type == '5'" class="img6" src="@/assets/imgs/STROKE_purple.png" />
    <img v-if="type == '6'" class="img7" src="@/assets/imgs/type6.png" />
    <img v-if="type == '7'" class="img7" src="@/assets/imgs/FULL_purple.png" />
    <img v-if="type == '7'" class="img7" src="@/assets/imgs/STROKE_purple.png" />
    <div class="content">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.button {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  width: 200px;
  height: 62px;
  background: none;
  margin: auto;
  cursor: pointer;
  .img5,
  .img6, .img7 {
    border: red;
    transition: transform 1s ease-in-out;
  }
  &:hover {
    .img5,
    .img6, .img7 {
      transform: rotateY(180deg);
    }
  }
}
.button:has(.img4) {
  margin: 0;
}
.button:has(.img5) {
  width: 230px;
}
.img1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: contain;
  z-index: -2;
}

.img2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 150px;
  height: auto;
  object-fit: cover;
  z-index: -1;
}
.img3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 15px;
  z-index: -1;
}
.img4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  height: auto;
  object-fit: contain;
  // margin-right: 20px;
  z-index: -1;
  & ~ .content {
    display: flex;
    align-content: center;
    justify-content: center;
    line-height: 60.5px;
    width: 100%;
    height: 60.5px;
    text-align: center;
  }
}
.img5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: contain;
  z-index: -2;
}

.img6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 150px;
  height: auto;
  object-fit: cover;
  z-index: -1;
}
.img7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: auto;
  object-fit: cover;
  z-index: -1;
}

.light {
  width: 100px;
  height: 100px;
  // top: -40px;
  right: -40px;
  filter: blur(50px);
  border-radius: 50%;
  position: absolute;
  background: $light;
}

@media screen and (max-width: $layout-breakpoint-large) {
}

@media screen and (max-width: $layout-breakpoint-medium) {
}
@media screen and (max-width: $layout-breakpoint-small) {
  .button {
    margin-top: 50px;
  }
}
</style>

<template>
  <div id="app" class="blueBG" v-if="!$store.state.general.privateLeague">
    <Topbar v-if="$route.name !== 'ComingSoon'" />
    <router-view />
    <Footer v-if="$route.name === 'Home' || $route.name === 'Create'" />
  </div>
  <div id="app" class="purpleBG" v-else>
    <Topbar v-if="$route.name !== 'ComingSoon'" />
    <router-view />
    <Footer v-if="$route.name === 'Home' || $route.name === 'Create'" />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: { Topbar, Footer },
  mounted() {
    //this.pullApi();
    // if (this.$route.params.id) {
    //   this.$store.state.general.privateLeague = true;
    // }else{
    //   this.$store.state.general.privateLeague = false;
    // }
    //this.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    //this.$store.state.general.privateLeague = false;
    //this.getLeaderboard();
    //console.log("Mobile device: " + this.isMobile());
  },
  methods: {
    pullApi() {
      const options = {
        method: "GET",
        url: "https://api-football-v1.p.rapidapi.com/v3/timezone",
        headers: {
          "X-RapidAPI-Key": "3e81641c5bmshc39a304d53dea6cp1982a4jsnceffb15a43a6",
          "X-RapidAPI-Host": "api-football-v1.p.rapidapi.com",
        },
      };

      this.axios
        .request(options)
        .then(function (response) {
          console.log("api-football-v1");
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
// https://www.figma.com/file/BKlpvUiOJfOufYjqYrdeZq/LEDGER_BET_PRIVATEleague?node-id=0%3A1
// https://worldsoccertalk.com/predictor/#group=c
// @import url("https://fonts.cdnfonts.com/css/matroska");
// @import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Square+Peg&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.cdnfonts.com/css/ethnocentric");

html,
body,
#app {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: $black;
  zoom: normal;
}
#app {
  font-family: "Space Mono";
  text-align: center;
  color: $white;
  margin: auto;
  // overflow-x: hidden;
  background-color: $black;
  //border: 1px solid red;
  min-height: 100vh;
}
.blueBG {
  // background-image: url("~@/assets/imgs/BG.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-image: linear-gradient(
    45deg,
    hsl(228deg 53% 13%) 0%,
    hsl(228deg 53% 13%) 14%,
    hsl(228deg 53% 13%) 34%,
    hsl(228deg 53% 13%) 52%,
    hsl(228deg 53% 13%) 64%,
    hsl(228deg 53% 13%) 74%,
    hsl(227deg 53% 16%) 81%,
    hsl(227deg 54% 19%) 87%,
    hsl(227deg 54% 23%) 92%,
    hsl(227deg 53% 26%) 96%,
    hsl(227deg 53% 30%) 100%
  );
}
.purpleBG {
  // background-image: url("~@/assets/imgs/PurpleBG.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  background-image: linear-gradient(45deg, hsl(270deg 53% 13%) 0%, hsl(270deg 52% 13%) 14%, hsl(270deg 51% 14%) 33%, hsl(270deg 50% 15%) 50%, hsl(270deg 49% 15%) 63%, hsl(270deg 49% 16%) 74%, hsl(270deg 48% 17%) 82%, hsl(269deg 47% 18%) 89%, hsl(269deg 47% 18%) 95%, hsl(269deg 46% 19%) 100%);
}
* {
  box-sizing: border-box;
}

a {
  color: $white;
  text-decoration: none;
}

.green {
  color: $green;
}
.orange {
  color: $orange;
}

.viewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  //border: 1px solid blue;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  //max-width: 900px;
  max-width: 1300px;
  padding-top: 150px;
  padding-bottom: 50px;
  z-index: 10;
  //border: 1px solid green;
  margin: auto;
}

.title1,
.title2 {
  //font-size: 50px;
  color: $text-color;
  font-family: "Space Mono";
  font-style: normal;
  text-transform: uppercase;
  font-size: clamp(25px, 2vw, 40px);
  //font-size: clamp(34px, 2vw, 50px);
  letter-spacing: 0.34em;
  //font-size: 40px;
  font-weight: 700;

  margin: auto;
  margin-bottom: 50px;
  user-select: none;
}

.title2 {
  //font-size: clamp(34px, 2vw, 50px);
  font-size: clamp(15px, 1.5vw, 30px);
  //font-size: 30px;
}

.text,
.textSmall {
  text-align: left;
  color: $text-color;
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.1em;
  font-size: 16px;
}
.text {
  font-weight: 700;
  letter-spacing: 0.3em;
  margin: 5px;
}
.textSmall {
  font-size: 15px;
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.howCanCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  margin: auto;
}

.howCanColItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 10px;
  margin-bottom: 20px;
  align-items: left;
}

button {
  font-family: "Space Mono";
  font-weight: 600;
  font-size: 20px;
  color: $white;
  background-color: $active;
  opacity: 0.85;
  text-align: center;
  text-transform: uppercase;
  // padding: 20px 50px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: all 0.1s;
  user-select: none;
}

img {
  user-select: none;
}

@media screen and (max-width: $layout-breakpoint-xlarge) {
  .center {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: $layout-breakpoint-large) {
  .title1 {
    // font-size: 40px;
    font-weight: 400;
  }
  .title2 {
    //font-size: 35px;
  }
  .text {
    font-size: 23px;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .title1 {
    // font-size: 30px;
    margin-bottom: 25px;
  }
  .title2 {
    //font-size: 25px;
    margin-bottom: 25px;
    line-height: 40px;
  }
  .text {
    font-size: 20px;
    font-weight: 300;
    line-height: 35px;
  }
  .center {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: $layout-breakpoint-small) {
  .title1 {
    // font-size: 25px;
  }
  .title2 {
    //font-size: 20px;
  }
  .text {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
  .center {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 850px) {
  .page {
    padding-top: 60px;
  }
}
</style>

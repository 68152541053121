import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    social: {
      twitter: 'https://twitter.com/ledgerbet',
      instagram: '',
      discord: 'https://discord.gg/ledgerbet',
      youtube: '',
      twitch: '',
    },
    general: {
      privateLeague: false,
      LeagueName: 'Test League',
      leaderboard: [],
    },
    user: {
      username: '',
      email: '',
      address: null,
      points: '',
      connected: false,
      bets: {},
    },
    matchs: [
      {
        id: 0,
        date: new Date("2022-10-20T17:00:00Z"),
        groupe: 'A',
        team1: 'qatar',
        team2: 'ecuador',
        score1: 0,
        score2: 0,
        //state: 'defaultState',
        checked: false,
      },
      {
        id: 1,
        date: new Date("2022-10-21T14:00:00Z"),
        groupe: 'B',
        team1: 'england',
        team2: 'iran',
        score1: 0,
        score2: 0,
        //state: 'defaultState',
        checked: false,
      },
      {
        id: 2,
        date: new Date("2022-11-21T17:00:00Z"),
        groupe: 'A',
        team1: 'senegal',
        team2: 'netherlands',
        score1: 0,
        score2: 0,
        //state: 'defaultState',
        checked: false,
      },
      {
        id: 3,
        date: new Date("2022-11-21T20:00:00Z"),
        groupe: 'B',
        team1: 'usa',
        team2: 'wales',
        score1: 0,
        score2: 0,
        //state: 'defaultState',
        checked: false,
      },
      {
        id: 4,
        date: new Date("2022-11-22T11:00:00Z"),
        groupe: 'C',
        team1: 'argentina',
        team2: 'saudi arabia',
        score1: 0,
        score2: 0,
        //state: 'defaultState',
        checked: false,
      },
      {
        id: 5,
        date: new Date("2022-11-22T14:00:00Z"),
        groupe: 'D',
        team1: 'denmark',
        team2: 'tunisia',
        score1: 0,
        score2: 0,
        //state: 'defaultState',
        checked: false,
      },
      {
        id: 6,
        date: new Date("2022-11-22T17:00:00Z"),
        groupe: 'C',
        team1: 'mexico',
        team2: 'poland',
        score1: 0,
        score2: 0,
        //state: 'defaultState',
        checked: false,
        live: true,
      },
      {
        id: 7,
        date: new Date("2022-11-22T20:00:00Z"),
        groupe: 'D',
        team1: 'france',
        team2: 'australia',
        score1: 0,
        score2: 0,
        //state: 'defaultState',
        checked: false,
        live: true,
      },
    ],
    faq: [
      {
        q: "What is the deadline to participate ?",
        r: "You can register for the competition until November 20th at 4pm UTC+1. You will not be able to register after that.",
      },
      {
        q: "How much can I bet ?",
        r: "We have a standardized bet amout of 0.3 Ethereum for each participant. Make sure to have some dust to pay for the gas fees.",
      },
      {
        q: "Can I bet twice ?",
        r: "You can bet twice if you want. You will need to use another Ethereum account to be able to do that.",
      },
      {
        q: "Do I need to pay gas when recording my forecasts on the blockchain ?",
        r: "Each forecast or group of forecasts recorded will cost aroun $3 in gas. If you want to be gas efficient, we suggest you forecast all the group games in the same transaction, as well as for the games in the knockout phase. It should cost you around $20 in gas fees for the entire competition by doing it this way.",
      },
      {
        q: "Can I change my bet after saving it on the blockchain ?",
        r: "You can change your bet any time before the beginning of each game, but it will cost you additional gas.",
      },
      {
        q: "Where can I see my score ?",
        r: "You will be able to see your score on the 'MY bet' page or directly by checking our leaderboard.",
      },
      {
        q: "What is the payout threshold ?",
        r: "The payout threshold varies depending on the prize pool and the number of participants. You can check it here.",
      },
      {
        q: "What if I have the same score as someone else ?",
        r: "If you have the same score as someone else, your rank will be displayed as the same. If the rank is eligible for payout, the payout of this particular rank will be split between the players.",
      },
      {
        q: "When will the payouts be sent ?",
        r: "The smart contract will send the payout a few hours after the final game of the tournaments with the Ethereum address you have been using to register.",
      },
      {
        q: "How points are calculated ?",
        r: "If your forecast is right, you will earn 1 point, and if you manage to get the exact score, you will earn 3 points. If, however, you had the wrong forecast you will earn 0 points. If you did not record the forecast on the blockchain before the game started, you will earn 0 points.",
      },
      {
        q: "What is your commission ?",
        r: "We are taking a 13% commission fee to operate, and it will be deducted directly from the smart contract. We are planning to release a complete peer-to-peer betting platform for other competitions. The funding will be used to create that. We are saving every Ethereum address from the participants so they will be eligible for a potential airdrop of our future tokens.",
      },
      {
        q: "I have some other questions; how can I reach out ?",
        r: "You can ask your questions in the live chat on our website, or join our Discord server here.",
      }
    ]
  }
})
